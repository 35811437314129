import React from "react"
import { connect, ConnectedProps } from "react-redux"

import { Layout } from "../components"
import { SEO } from "../components/common"
import { store } from "@/types/interface"
import { excursionLessonLoad } from "../actions"
import { SpecialLessonCard } from "../components/common"
import styles from "./excursion.module.css"

const connector = connect(
  // mapStateToProps
  ({ excursionLesson }: { excursionLesson: store.ExcursionLesson }) => ({
    excursionLessons: excursionLesson.excursionLessons,
  }),
  // mapDispatchToProps
  { excursionLessonLoad }
)

type Props = ConnectedProps<typeof connector>

const ExcursionLessons: React.FC<Props> = ({
  excursionLessons,
  excursionLessonLoad,
}) => {
  React.useEffect(() => {
    document &&
      document.documentElement.style.setProperty("--color-primary", "#550000")
    excursionLessonLoad()

    return () =>
      document.documentElement.style.setProperty("--color-primary", "#16424D")
  }, [])

  return (
    <Layout>
      <SEO title="Culture House 大人の遠足" />
      <div className="container">
        <h1 className={styles.title}>大人の遠足</h1>
        <h2 className={styles.subTitle} style={{ background: colors[0] }}>
          Culture House企画
        </h2>
        {excursionLessons
          ?.filter(lesson => lesson.lessonType === "CH")
          .map(lesson => (
            <SpecialLessonCard
              key={lesson._id}
              lesson={lesson}
              color={colors[0]}
            />
          ))}
        <h2 className={styles.subTitle} style={{ background: colors[1] }}>
          レストラン講座
        </h2>
        {excursionLessons
          ?.filter(lesson => lesson.lessonType === "restaurant")
          .map(lesson => (
            <SpecialLessonCard
              key={lesson._id}
              lesson={lesson}
              color={colors[1]}
            />
          ))}
      </div>
    </Layout>
  )
}

const colors = ["rgb(186, 157, 125)", "rgb(235, 86, 66)"]

export default connector(ExcursionLessons)
